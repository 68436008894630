import './Table.css'
import './runbookTemplate.css'
import * as React from 'react';
import { Link } from 'react-router-dom'
import { useReactTable, createColumnHelper, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Button, ButtonGroup, ToggleButton, Modal, Form } from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import {callApiServer} from '../utils/api';
import { Navigate, useNavigate } from 'react-router-dom';


//TData
type Step = {
  title: string
  description: string
  script?: {
    id: string
    name: string
  }
}

interface TableProps {
  steps: Step[]
  template_id: string
}

export default function RunbookTemplate(props: React.PropsWithChildren<TableProps>) {

  const [checked, setChecked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [runbookName, setRunbookName] = React.useState({name:''});
  const [newRunbookId, setNewRunbookId] = React.useState(null);
  const nagivate = useNavigate();

  const { steps, template_id } = props

  //Toggle New Runbook Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Collect runbook name from user
  const handleChange = (event) => {
    setRunbookName({name: event.target.value});
  }

  //Create Runbook when a Runbook name is given by user
  const createRunbook = async (event) => {
    //Keep page from reloading
    event.preventDefault();

    //Send post request to backend, retrieve response
    const payload = {name: runbookName.name, template_id: template_id}
    const r = await callApiServer('/create-runbook/', {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then(response => {
      const status = response.status;
      if (status == 200) {
        console.log("Create New Runbook")
        //If successful, take user to new runbook's page
        return response.json().then(data => setNewRunbookId(data.id))
      }
      else if (status == 409) {
        //Else if name is already in use, display error message within modal
        alert("Runbook name already in use. Please try again.")
      }
      else {
        //Else, close modal and display error message "Something when wrong, please try again"
        console.log("Error: ", response.status)
        alert("Something went wrong. Please try again or contact an administrator if issue persists.")
      }
    }).catch(error => {
      console.error(error);
    });
  }
  
  // Load page for new runbook when one is created
  React.useEffect(() => {
    if (newRunbookId !== null) {
      nagivate(`/runbooks/${newRunbookId}`);
    }
  })

  //Custon Cell Renderers
  const scriptCell = (cell) => {
    if (cell.getValue() == undefined) {
      return (
        <></>
      )
    }
    const url = `/resource/${cell.getValue().owl_id}`;
    return (
      <Link to={url} className='script-link'>{cell.getValue().name}</Link>
    )
  }

  // Column Definitions
  const columnHelper = createColumnHelper<Step>()

  const columns = [
    columnHelper.accessor('title', {
        header: () => <span>Title</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('description', {
        header: () => <span>Description</span>,
        cell: cell => <div dangerouslySetInnerHTML={{ __html: cell.getValue() }}/>,
        enableSorting: false,
    }),
    columnHelper.accessor('script', {
        header: () => <span>Script</span>,
        cell: scriptCell,
        enableSorting: false,
    }),
  ]

    const runbook_template = useReactTable({
      data: steps,
      columns,
      getCoreRowModel: getCoreRowModel(),
    })



  return (
    <>
      <div className='toggle-button-container'>
        <ButtonGroup aria-label='Template Toggle'>
          <ToggleButton
            className="mb-2"
            id='toggle-check'
            type='checkbox'
            variant='outline-secondary'
            checked={checked}
            value='1'
            onChange={(e) => setChecked(e.currentTarget.checked)}
            style={{flex:1, width:180}}
          >
            Template
          </ToggleButton>
          <ToggleButton
            className="mb-2"
            id='toggle-check'
            type='checkbox'
            variant='outline-secondary'
            checked={!checked}
            value='1'
            onChange={(e) => setChecked(e.currentTarget.checked)}
            style={{flex:1, width:180}}
          >
            My Runbooks
          </ToggleButton>
        </ButtonGroup>
      </div>
      <div className='runbook-template-table' style={{ display: 'flex', padding: 5}}>
        <table>
          <thead>
              {runbook_template.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {runbook_template.getRowModel().rows.map((row, index) => (
                <tr key={row.id} className={index % 2 == 0? 'even-row':'odd-row'}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', flexGrow:1}}>
      </div>
      <div className='right-button-container'>
        <Button className='runbook-button' variant='secondary' onClick={handleShow}>
          Create New
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Runbook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={createRunbook}>
            <Form.Label>Enter unique runbook name</Form.Label>
            <Form.Control onChange={handleChange} autoFocus/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} style={{marginRight:'auto'}}>
            Close
          </Button>
          <Button variant="primary" onClick={createRunbook} style={{backgroundColor:'#202D69'}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}